<template>
  <div class="container">
    <div>
      <Header></Header>
      <div class="login-content">
        <!-- Animated -->
        <div class="animated fadeIn">
          <router-view/>
        </div>
        <!-- .animated -->
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Login',
};
</script>
