import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: { Auth: false, title: 'Login' },
    beforeEnter: (to, from, next) => {
      // Si existe un token, la sesion existe, por lo cual, redirecciona a home
      // if (window.localStorage.getItem('_token')) {
      if (window.localStorage.getItem('_token')) {
        next({ path: '/' });
      } else {
        next();
      }
    },
  },
  {
    path: '*',
    redirect: '/login',
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: { Auth: true, title: 'Inicio' },
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: { Auth: true, title: 'About' },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue'),
    meta: { Auth: true, title: 'Configuraciones' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: { Auth: true, title: 'Perfil' },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('../views/Notifications.vue'),
    meta: { Auth: true, title: 'Notificaciones' },
  },
  {
    path: '/sanciones',
    name: 'Sanciones',
    component: () => import('../views/Sanciones.vue'),
    meta: { Auth: true, title: 'Sanciones' },
  },
  {
    path: '/courses',
    name: 'Course',
    component: () => import('../views/Courses.vue'),
    meta: { Auth: true, title: 'Cursos' },
  },
  {
    path: '/assistance',
    name: 'Asistancie',
    component: () => import('../views/Assistance.vue'),
    meta: { Auth: true, title: 'Asistencia' },
  },
  {
    path: '/tasks/:course_id?/:unidad_id?/',
    name: 'Tasks',
    component: () => import('../views/Tasks.vue'),
    meta: { Auth: true, title: 'Actividades' },
  },
  {
    path: '/Notes',
    name: 'notes',
    component: () => import('../views/Notes.vue'),
    meta: { Auth: true, title: 'Notas' },
  },
  {
    path: '/top',
    name: 'top',
    component: () => import('../views/Top.vue'),
    meta: { Auth: true, title: 'Top' },
  },
  {
    path: '/percent_assistant',
    name: 'Porcentaje_asistencia',
    component: () => import('../views/AsistenciaCurso.vue'),
    meta: { Auth: true, title: 'Porcentaje de asistencia' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.Auth && !window.localStorage.getItem('_token')) {
    next({ path: '/login' });
  } else {
    next();
  }
});

export default router;
