import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    layout: 'login',
    isLoading: false,
    progress: 0,
    user: JSON.parse(localStorage.getItem('user')) || null,
    settings_obj: JSON.parse(localStorage.getItem('settings')) || null,
    token: JSON.parse(localStorage.getItem('_token')) || false,
  },
  mutations: {
    SET_AUTH(state, user) {
      let secuntuser = user;
      state.token = true;
      if (!user) {
        state.token = false;
        secuntuser = {
          first_name: '',
          last_name: '',
          email: '',
          exp: '',
        };
      }
      state.user = secuntuser;
    },
    SET_LAYOUT(state, name) {
      state.layout = name;
    },
    SET_status_request(state, status) {
      state.progress = status;
      if (!status) {
        state.progress = 0;
      }
    },
    set_settings(state, settings) {
      let temp = settings;
      if (!settings) {
        temp = {};
      }
      state.settings_obj = temp;
    },
    set_avatar_url(state, avatarUrl) {
      state.settings_obj.avatar_url = avatarUrl;
    },
    set_avatar_id(state, avatarId) {
      state.settings_obj.avatar = avatarId;
    },
  },
  getters: {
    is_login(state) {
      return state.token;
    },
    register(state) {
      return state.token;
    },
    student_name(state) {
      if (state.settings_obj) {
        return state.settings_obj.student_name;
      }
      return null;
    },
  },
  actions: {
    status_request({ commit }, { progress }) {
      commit('SET_status_request', progress);
    },
    reset_status_request({ commit }) {
      commit('RESET_status_request', null);
    },
    set_data_settgins({ commit }, { settings }) {
      commit('set_settings', settings);
    },
    set_avatar_url({ commit }, { avatarUrl }) {
      commit('set_avatar_url', avatarUrl);
    },
    set_avatar_id({ commit }, { avatarId }) {
      commit('set_avatar_id', avatarId);
    },
    async login({ commit }, { user }) {
      await commit('SET_AUTH', user);
    },
    set_layout({ commit }, { name }) {
      commit('SET_LAYOUT', name);
    },
    logout({ commit }) {
      // se setea a null el objeto user
      commit('SET_AUTH', null);
      commit('set_settings', null);
      // se remueve la data de locall storage
      localStorage.removeItem('user');
      localStorage.removeItem('settings');
      localStorage.removeItem('_token');
    },
  },
  modules: {
  },
});
