<template>
  <div>
    <Leftbar></Leftbar>
    <div id="right-panel" class="right-panel">
      <div class="btn btn-outline-success float-right" @click="installer()"
        :style="{ 'display': installBtn}">
        Install
      </div>
      <Header></Header>
      <div class="content">
        <!-- Animated -->
        <div class="animated fadeIn">
          <transition name="fade" mode="out-in" :duration="{ enter: 300, leave: 800 }">
            <router-view/>
          </transition>
        </div>
        <!-- .animated -->
      </div>
      <div class="clearfix"></div>
      <Footer></Footer>
    </div>
  </div>
</template>
<script>

export default {
  created() {
    let installPront;
    window.addEventListener('beforeinstallpront', (e) => {
      e.preventDefault();
      installPront = e;
      this.installBtn = 'block';
    });
    this.installer = () => {
      this.installBtn = 'none';
      installPront.prompt();
      installPront.userChoice.then((result) => {
        if (result.outcome === 'accepted') {
          console.log('User accepted');
        } else {
          console.log('User denied');
        }
        installPront = null;
      });
    };
  },
  mounted() {
    document.querySelector('body').classList.toggle('open');

    document.querySelectorAll('.menu-item-has-children.dropdown').forEach((el) => {
      el.addEventListener('click', () => {
        const tempText = el.querySelector('.dropdown-toggle').innerHTML;
        el.childNodes[1].insertAdjacentHTML('afterbegin', `<li class="subtitle">${tempText}</li>`);
      });
    });
    window.addEventListener('resize', () => {
      const windowWidth = window.screen.width;
      if (windowWidth < 1010) {
        document.body.classList.add('small-device');
      } else {
        document.body.classList.remove('small-device');
      }
    });

    const windowWidth = window.screen.width;
    if (windowWidth < 1010) {
      document.body.classList.add('small-device');
    } else {
      document.body.classList.remove('small-device');
    }
  },
  name: 'Default',
  components: {
    Header: () => import('../../views/Header.vue'),
    Footer: () => import('../../views/Footer.vue'),
    Leftbar: () => import('../../views/Leftbar.vue'),
  },
  data() {
    return {
      installBtn: 'none',
      installer: undefined,
    };
  },
};
</script>
<style lang="css" scoped>
  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .fade-enter-active {
    transition: all .5s ease;
  }
  .fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
