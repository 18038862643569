<template>
  <div id="app">
      <component :is="layout"></component>
  </div>
</template>
<script>

import Default from '@/components/layouts/default.vue';
import Login from '@/components/layouts/login.vue';

export default {
  created() {
    if (this.$store.state.token) {
      this.$store.commit('SET_LAYOUT', 'default');
    }
  },
  name: 'App',
  components: {
    Default,
    Login,
  },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
  },
};
</script>
