/* eslint-disable no-param-reassign */
import Vue from 'vue';
import jQuery from 'jquery';
import Axios from 'axios';
import Swal from 'sweetalert2';
import App from './App.vue';
import router from './router';
import store from './store';

import './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import 'font-awesome/css/font-awesome.css';
import '@icon/stroke-7/stroke-7.css';
import '@icon/stroke-7/icons/album.svg';
import '@/assets/css/cs-skin-elastic.css';
import '@/assets/css/style.css';

const $ = jQuery;
window.jQuery = jQuery;
window.$ = $;

/* const requestHandler = (request, jwtToken) => {
  // eslint-disable-next-line no-param-reassign
  // eslint-disable-next-line dot-notation
  request.headers['Authorization'] = `JWT ${jwtToken}`;
  // eslint-disable-next-line no-param-reassign
  request.headers['Accept-Language'] = 'es';
  return request;
}; */
Vue.prototype.$swal = Swal;
Vue.prototype.$http = Axios.create({
  onUploadProgress: (progressEvent) => {
    store.dispatch('status_request', { progress: 0 });
    const p = Math.round((progressEvent.loaded / progressEvent.total) * 100);
    store.dispatch('status_request', { progress: p });
  },
  baseURL: process.env.VUE_APP_BASE_API_URL,
});

Vue.prototype.$http.interceptors.request.use(
  (request) => {
    // const user = localStorage.getItem('user');
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      // eslint-disable-next-line no-param-reassign
      // eslint-disable-next-line dot-notation
      request.headers['Authorization'] = `JWT ${user.token}`;
      // requestHandler(request, store.state.user.token);
    }

    return request;
  },
);

Vue.prototype.$http.interceptors.request.use((config) => {
  store.state.isLoading = true;
  return config;
}, (error) => {
  store.state.isLoading = false;
  return Promise.reject(error);
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
